






























































@import "../../styles/vars";
.popover-modal.modal-post.post-more-popover {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: var(--win-height, 100vh);

  .content-wrap {
    width: 100%;
    max-width: 65ch;
    max-height: min(calc(var(--win-height) - 1rem * 2), 600px);
    .content {
      width: 100%;
      max-width: 65ch;
      padding: $base-padding;
      height: auto;
      overflow: auto;
      border: solid rgba(0, 0, 0, 0.41);
      border-width: 1px 0;

      .attachment-box {
      }
    }
  }
  .modal-shadow {
    //background: transparent;
  }
}
