










































































































@import "../styles/vars";
.popover-modal.modal-post {
  --footer-height: #{$base-size * 0.75};
  --max-box-width: 800px;
  --max-box-height: 450px;
  --box-margins: #{$base-padding};

  //font-family: #{$font-family-sans-serif};
  background: transparent;
  //padding-bottom: var(--footer-height);
  height: 100%;
  // max-height: var(--max-box-height);

  &.fit-in-width {
    --width-formula: min(calc(100vw - #{$base-padding} * 2), var(--max-box-width));
    width: calc(var(--width-formula));
    height: calc(var(--width-formula) / var(--modal-box-ratio, 1) + var(--footer-height));
  }

  &.fit-in-height {
    --height-formula: min(calc(100vh - #{$base-padding} * 3 - var(--footer-height)), var(--max-box-height));
    height: calc(var(--height-formula) + var(--footer-height));
    width: calc(var(--height-formula) * var(--modal-box-ratio, 1));
  }
}
