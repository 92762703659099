




















































































































































































































































































































































































































.more-popup{
  position: fixed;
  //width: 100vw;
  //height: auto;
  //max-width: calc(100vw - 20px);
  //max-height: calc(var(--win-height) - 20px);
  //top: 0;
  //left: 0;
  overflow: auto;
  z-index: 2000000;
}
