




























.edit-button-badge {
  .internal {
    @apply bg-gray-900 text-white;
  }
  .draft {
    @apply bg-aba-blue text-white;
  }
}
